<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('FORMS.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-row>
        <v-col cols="12" sm="12" md="12">
          <RoleModalForm
            :modalData="modalData"
            :authPermissions="authPermissions"
            @closeModalForm="handleCloseModalForm"
            @saveModalForm="handleSaveModalForm"
          ></RoleModalForm>
        </v-col>
      </v-row>
      <AlertComponent
        :alertShow="alert.show"
        :alertText="alert.text"
        @closeAlert="alert.show = false"
      ></AlertComponent>
      <v-data-table
        :headers="headers"
        :items="collection"
        :search="search"
        :sort-by="['name']"
        @click:row="handleClickItem"
      >
        <template v-slot:item.eu="{ item }">
          {{ eu[item.eu] }}
        </template>
        <template v-slot:item.actions="{ item }">
          <!-- <router-link :to="`/settings/roles/${item.id}`">
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
          </router-link> -->
          <v-icon
            small
            color="primary"
            v-if="permissionCan('delete')"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">{{
          $t("TABLE.are_you_sure_delete")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDelete">{{
            $t("TABLE.cancel")
          }}</v-btn>
          <v-btn color="primary" @click="deleteItemConfirm">{{
            $t("TABLE.ok")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";
import RoleModalForm, { initialFormData } from "./RoleModalForm";
import AlertComponent from "@/view/components/AlertComponent";
// import { initialFormData } from "./RoleModalForm";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_ROLE,
  ROLE_COLLECTION,
} from "@/core/services/store/role.module.js";

export default {
  name: "Roles",
  components: { RoleModalForm, AlertComponent },
  data() {
    return {
      cardTitle: this.$t("FORMS.roles"),
      showAll: false,
      search: "",
      collection: [],
      authPermissions: [],
      routePath: "/settings/roles/",
      eu: ["no", "yes"],
      alert: {
        show: false,
        text: this.$t("TABLE.cannot_delete"),
      },
      headers: [
        {
          text: this.$t("TABLE.name"),
          value: "name",
        },
        // {
        //   text: "Guard Name",
        //   value: "guard_name",
        // },
        { text: this.$t("TABLE.actions"), value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        editedIndex: -1,
        editedItem: initialFormData,
        defaultItem: initialFormData,
      },

      dialogDelete: false,
    };
  },

  computed: {
    ...mapGetters([ROLE_COLLECTION]),
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    showAll(val) {
      if (this[ROLE_COLLECTION].length > 0) {
        if (!val) {
          this.collection = this[ROLE_COLLECTION];
        } else {
          this.collection = this.collection.filter((item) => {
            return item.status == 1;
          });
        }
      }
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
    roleCollection(val) {
      this.collection = val;
    },
  },

  methods: {
    ...mapActions([FETCH_ROLE]),
    editItem(id) {
      ApiService.get("role/" + id)
        .then(({ data }) => {
          this.modalData.editedIndex = 1;
          this.modalData.editedItem = Object.assign({}, data);
          this.modalData.dialog = true;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    deleteItem(item) {
      if (item.users.length > 0) {
        this.alert.show = true;
      } else {
        this.editedIndex = this[ROLE_COLLECTION].indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      }
    },

    deleteItemConfirm() {
      ApiService.delete("role/" + this.editedItem.id)
        .then(() => {
          this.fetchRole();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.modalData.editedIndex = -1;
      this.modalData.editedItem = this.modalData.defaultItem;
      this.$router.push({ name: "roles" });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    handleSaveModalForm(model) {
      model.users = model.users.map(function (item) {
        return item.id;
      });
      model.permissions = model.permissions.map(function (item) {
        if (item) {
          return item.id;
        }
      });

      if (this.modalData.editedIndex > -1) {
        ApiService.put("role/" + model.id, model)
          .then(() => {
            this.fetchRole();
            this.handleCloseModalForm();
          })
          .catch((error) => {
            this.warningAlert.text = error.message;
            this.warningAlert.show = true;
            console.log("Error!: ", error);
          });
      } else {
        //create model
        ApiService.post("role", model)
          .then(({ data }) => {
            console.log(data);
            this.fetchRole();
            this.handleCloseModalForm();
          })
          .catch((error) => {
            console.log("Error!: ", error);
          });
      }
    },

    handleClickItem(item) {
      if (
        this.permissionCan("view") &&
        !this.dialogDelete &&
        !this.alert.show
      ) {
        this.$router.push(this.routePath + item.id);
      }
    },

    permissionCan(action) {
      return this.authPermissions.find(
        (x) => x.name == "system.role." + action
      );
    },
    setPermissions() {
      if (localStorage.permissions) {
        this.authPermissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("role") > -1
        );
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "currency" }]);
    this.fetchRole();
    this.setPermissions();
  },
};
</script>
